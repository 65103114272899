export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      // {
      //   title: 'Home',
      //   route: 'home',
      //   icon: 'HomeIcon',
      // },
      // {
      //   title: 'Clients',
      //   route: 'clients-page',
      //   icon: 'UsersIcon',
      // },
      // {
      //   title: 'Groups',
      //   route: 'groups-page',
      //   icon: 'PlusSquareIcon',
      // },
      //
      // {
      //   title: 'Settings',
      //   route: 'settings-page',
      //   icon: 'SettingsIcon',
      // },
      // {
      //   title: 'Reports',
      //   route: 'reports-page',
      //   icon: 'LayersIcon',
      // },
    ],
  },
]
